import React, { useState } from 'react'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'

import redefining from '@/assets/images/home/suit/redefining.png'
import guide from '@/assets/images/home/suit/guide.png'
import managers from '@/assets/images/home/suit/managers.png'
import hcp from '@/assets/images/home/suit/hcp.png'
import rotateFrontImage from '@/assets/images/home/suit/rotate-front.png'
import rotateBackImage from '@/assets/images/home/suit/rotate-back.png'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

const RotateCard = props => {
	const { title, subtitle, desc, image } = props
	const [rotate, setRotate] = useState(false)
	const { isMobile } = useSelector(state => state.settings)

	const handleClickCard = () => {
		setRotate(!rotate)
	}

	return (
		<li className={styles.rotate_card_wrap}>
			<div
				onClick={handleClickCard}
				style={{
					transform: rotate ? 'rotateY(-180deg)' : 'rotateY(0deg)',
				}}
				className={styles.rotate_card}
			>
				<div className={styles.rotate_card_front}>
					<h3>{title}</h3>

					<p>{subtitle}</p>

					<div className={styles.rotate_card_image}>
						<img src={image} alt='image' />
					</div>

					<div className={styles.rotate_card_icon}>
						<img
							src={!isMobile ? rotateFrontImage : rotateBackImage}
							alt='rotate-front'
						/>
					</div>
				</div>

				<div className={styles.rotate_card_back}>
					<RootDesc>{desc}</RootDesc>

					{!isMobile && (
						<div className={styles.rotate_card_icon}>
							<img src={rotateBackImage} alt='rotate-back' />
						</div>
					)}
				</div>
			</div>
		</li>
	)
}

export const RotateCards = () => {
	const rotateCards = [
		{
			id: 0,
			title: <>AVA AI Trainer</>,
			subtitle: (
				<>
					Redefining Sales <br /> Training
				</>
			),
			desc: (
				<>
					<span>
						The flagship simulation trainer for sales reps, AVA AI&nbsp;Trainer
						is the gateway to&nbsp;immersive, dynamic learning experiences.
					</span>
					<span>
						<b>Practice in&nbsp;Safe Simulations:</b> Sales reps sharpen their
						skills in&nbsp;a&nbsp;<nobr>risk-free</nobr> environment with
						virtual HCPs, tailored to&nbsp;replicate <nobr>real-world</nobr>{' '}
						scenarios.
					</span>
					<span>
						<b>Bridging Sales and Marketing:</b> Marketing teams along with
						Sales Managers and Trainers can upload content or&nbsp;updates (like
						revised product messaging) into AVA’s&nbsp;training library. Sales
						reps quickly internalise these changes through{' '}
						<nobr>fast-track</nobr>, interactive content using video avatars
						combined with interactive questions to&nbsp;provide an&nbsp;engaging
						and efficient learning experience.
					</span>
					<span>
						<b>Edutainment Excellence:</b> Video avatars, quests, and mini games
						turn routine training into an&nbsp;engaging experience, boosting
						confidence and retention.
					</span>
				</>
			),
			image: redefining,
		},
		{
			id: 1,
			title: <>AVA AI Mentor</>,
			subtitle: <>The Always-On Guide</>,
			desc: (
				<>
					<span>
						AVA AI&nbsp;Mentor acts like a&nbsp;virtual assistant for sales
						reps, offering proactive, personalised support 24/7.
					</span>
					<ul>
						<li>
							<b>Proactive Engagement:</b> AVA Mentor checks in&nbsp;with reps,
							offering tailored learning content based on&nbsp;their recent
							performance and career stage.
						</li>
						<li>
							<b>Answering Questions Instantly:</b> From product details
							to&nbsp;compliance queries, AVA Mentor saves time
							by&nbsp;automating responses, so&nbsp;trainers can focus
							on&nbsp;strategic initiatives.
						</li>
						<li>
							<b>Boosting Productivity:</b> Reps spend just 28% of&nbsp;their
							week actually selling, with the majority of&nbsp;their time
							consumed by&nbsp;other tasks like deal management and data entry.
							AVA Mentor streamlines workflows, empowering reps to&nbsp;focus
							on&nbsp;what matters&nbsp;&mdash; closing deals and driving
							results.
						</li>
					</ul>
				</>
			),
			image: guide,
		},
		{
			id: 2,
			title: <>AVA RepCoPilot</>,
			subtitle: (
				<>
					Empowering Managers <br /> with Insights
				</>
			),
			desc: (
				<>
					<span>
						AVA RepCoPilot provides detailed performance analysis on
						face-to-face or&nbsp;remote calls.
					</span>
					<ul>
						<li>
							<b>Call Analytics:</b> Evaluates rep interactions with HCPs,
							highlighting strengths, weaknesses, and emotional triggers.
						</li>
						<li>
							<b>Actionable Feedback:</b> Delivers customised strategies for
							improvement, helping reps refine their approach.
						</li>
						<li>
							<b>Performance Reports:</b> Offers detailed insights
							to&nbsp;managers, enabling targeted coaching that’s&nbsp;rooted
							in&nbsp;data.
						</li>
					</ul>
					<span>
						With AVA RepCoPilot, managers become more than
						supervisors&nbsp;&mdash; they’re mentors armed with actionable
						insights.
					</span>
				</>
			),
			image: managers,
		},
		{
			id: 3,
			title: <>CLM Suite</>,
			subtitle: (
				<>
					Elevating HCP <br />
					Engagement <br />
					with Dynamic <br />
					Presentations
				</>
			),
			desc: (
				<>
					<span>
						Our CLM Slides are crafted to&nbsp;captivate HCP attention
						by&nbsp;delivering customised story flows, dynamic visuals, and
						tailored medical content. Enhancing the effectiveness of&nbsp;each
						call and interaction.
					</span>
					<ul>
						<li>
							<b>Personalisation:</b> Customised content ensures that every
							presentation speaks directly to&nbsp;the needs and interests
							of&nbsp;the HCP, driving deeper engagement.
						</li>
						<li>
							<b>
								<nobr>Follow-Up</nobr>:
							</b>{' '}
							Previous key discussion points seamlessly integrate from the CRM
							into future presentations, ensuring continuity and reinforcing
							past interactions.
						</li>
						<li>
							<b>Content Animations:</b> <nobr>Attention-grabbing</nobr>{' '}
							animations highlight the most important details, ensuring that the
							HCP remains focused on&nbsp;critical messages.
						</li>
						<li>
							<b>Visual Appeal:</b> Advanced design elements make each slide
							visually compelling, boosting the overall impact of&nbsp;your
							presentation and leaving a&nbsp;lasting impression.
						</li>
						<li>
							<b>Surveys:</b> Gather <nobr>real-time</nobr> insights from HCPs
							to&nbsp;better understand their needs and tailor future
							interactions accordingly.
						</li>
					</ul>
				</>
			),
			image: hcp,
		},
	]

	return (
		<ul className={styles.rotate_cards}>
			{rotateCards &&
				rotateCards.length > 0 &&
				rotateCards.map(card => (
					<React.Fragment key={card.id}>
						<RotateCard
							title={card.title}
							subtitle={card.subtitle}
							desc={card.desc}
							image={card.image}
						/>
					</React.Fragment>
				))}
		</ul>
	)
}
