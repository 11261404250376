import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { LibraryLayout } from '@/components/layouts/LibraryLayout'
import doctorObjections from '@/assets/example-videos/doctor-objections.jpg'
import sideEffects from '@/assets/example-videos/side-effects.png'
import painxicam from '@/assets/example-videos/painxicam.png'
import rolexin from '@/assets/example-videos/rolexin.png'
import { useTranslation } from 'react-i18next'

export const Library = () => {
	const { t } = useTranslation()

	const videos = useMemo(
		() => [
			{
				id: uuidv4(),
				title: t('video_1.name'),
				description: t('video_1.desc'),
				category: t('video_1.line'),
				time: t('video_1.time'),
				image: doctorObjections,
			},
			{
				id: uuidv4(),
				title: t('video_2.name'),
				description: t('video_2.desc'),
				category: t('video_2.line'),
				time: t('video_2.time'),
				image: rolexin,
			},
			{
				id: uuidv4(),
				title: t('video_3.name'),
				description: t('video_3.desc'),
				category: t('video_3.line'),
				time: t('video_3.time'),
				image: painxicam,
			},
			{
				id: uuidv4(),
				title: t('video_4.name'),
				description: t('video_4.desc'),
				category: t('video_4.line'),
				time: t('video_4.time'),
				image: sideEffects,
			},
		],
		[]
	)

	return (
		<div className='container-big'>
			<div className={styles.library}>
				<LibraryLayout videos={videos} />
			</div>
		</div>
	)
}
