import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { HeroVideo } from '../components/HeroVideo'
import { HeroTitle } from '../components/HeroTitle'

import circle from '@/assets/images/home/suit/circle.png'
import customer from '@/assets/images/home/suit/customer.svg'
import product from '@/assets/images/home/suit/product.svg'
import adaptability from '@/assets/images/home/suit/adaptability.svg'
import data from '@/assets/images/home/suit/data.svg'
import roi from '@/assets/images/home/suit/roi.svg'

import styles from './styles.module.scss'

const CircleItem = ({ icon, title, subtitle, isActive, onClick }) => {
	const { isMobile } = useSelector(state => state.settings)

	const handleClickItem = () => {
		if (isMobile) {
			onClick()
		}
	}

	useEffect(() => {
		if (!isMobile) {
			onClick(null)
		}
	}, [isMobile])

	return (
		<article onClick={handleClickItem} className={styles.intro_circle_item}>
			<img src={icon} alt='icon' />

			<span>{title}</span>

			<i
				style={
					isActive
						? {
								opacity: '1',
								pointerEvents: 'all',
								visibility: 'visible',
								zIndex: '20',
						  }
						: {}
				}
			>
				{subtitle}
			</i>
		</article>
	)
}

export const Intro = () => {
	const [activeCard, setActiveCard] = useState(null)
	const { isMobile } = useSelector(state => state.settings)

	const circleItems = [
		{
			id: 0,
			icon: customer,
			title: (
				<>
					Customer <br /> Engagement
				</>
			),
			subtitle: (
				<>
					Track and improve the quality <br />
					of&nbsp;engagement with HCPs
				</>
			),
		},
		{
			id: 1,
			icon: product,
			title: (
				<>
					Product <br /> Knowledge
				</>
			),
			subtitle: (
				<>
					Assess and deepen MRs <br />
					knowledge of&nbsp;the products
				</>
			),
		},
		{
			id: 2,
			icon: adaptability,
			title: <>Adaptability</>,
			subtitle: (
				<>
					Easily adapt to&nbsp;any market <br />
					changes
				</>
			),
		},
		{
			id: 3,
			icon: data,
			title: <>Data Analysis</>,
			subtitle: (
				<>
					Support informed decision- <br />
					making and improve efficiency
				</>
			),
		},
		{
			id: 4,
			icon: roi,
			title: (
				<>
					Measuring <br /> ROI
				</>
			),
			subtitle: (
				<>
					Accurately assess the ROI <br />
					from your sales activities
				</>
			),
		},
	]

	useEffect(() => {
		if (!isMobile) {
			setActiveCard(null)
		} else {
			const handleClick = () => setActiveCard(null)
			document.addEventListener('mousedown', handleClick)

			return () => {
				document.removeEventListener('mousedown', handleClick)
			}
		}
	}, [isMobile])

	return (
		<div className={styles.intro}>
			<HeroVideo top={'-550'} />

			<HeroTitle>
				<span>
					<strong>AVA</strong> AI Trainer
				</span>

				<span>COSMART SUITE</span>
			</HeroTitle>

			<div className={styles.intro_bottom}>
				<p>
					<span>
						Since 2014, Vivanti has been dedicated to&nbsp;delivering impactful
						solutions for pharmaceutical commercial and marketing teams.
					</span>
					<span>
						Recognising the transformative potential of&nbsp;AI&nbsp;and
						evolving industry trends, we&nbsp;developed the COSMART Suite.
					</span>
					<span>
						An&nbsp;AI-driven Commercial Optimisation platform designed
						to&nbsp;drive innovation, maximise field force efficiency, and
						refine performance standards.
					</span>
				</p>

				<div className={styles.intro_circle}>
					<div className={styles.intro_circle_image}>
						<img src={circle} alt='circle' />
					</div>

					<h3>
						COSMART <br /> Suite
					</h3>

					{circleItems &&
						circleItems.length > 0 &&
						circleItems.map((item, index) => (
							<React.Fragment key={item.id}>
								<CircleItem
									icon={item.icon}
									title={item.title}
									subtitle={item.subtitle}
									isActive={activeCard === index + 1}
									onClick={() =>
										setActiveCard(activeCard === index + 1 ? null : index + 1)
									}
								/>
							</React.Fragment>
						))}
				</div>
			</div>
		</div>
	)
}
