import React, { useEffect, useRef, useState } from 'react'

import { BorderArticle } from '@/components//ui/general/BorderArticle'

import minVol from '@/assets/images/chat/vol-min.svg'
import maxVol from '@/assets/images/chat/vol-max.svg'
import muteVol from '@/assets/images/chat/vol-mute.svg'

import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setVolume } from '@/redux/slices/avatarSlices/chatAvatarSlice'

export const Controls = () => {
	const [showControls, setShowControls] = useState(false)
	const { isMobile } = useSelector(state => state.settings)
	const { volume } = useSelector(state => state.chatAvatar)

	const dispatch = useDispatch()
	const volumeRef = useRef(null)
	const controlsRef = useRef(null)

	const handleVolumeChange = event => {
		dispatch(setVolume(+event.target.value))
	}

	useEffect(() => {
		if (volumeRef && volumeRef.current && !isMobile) {
			const maxWidth = volumeRef.current.offsetWidth - 8
			const position = (volume / 100) * maxWidth

			volumeRef.current.style.setProperty(
				'--i-position',
				`${position * 100}rem`
			)
		} else if (volumeRef && volumeRef.current && isMobile) {
			const maxHeight = volumeRef.current.offsetHeight - 8
			const position = (volume / 100) * maxHeight

			volumeRef.current.style.setProperty(
				'--i-position',
				`${position * 100}rem`
			)
		}
	}, [volume, volumeRef])

	useEffect(() => {
		if (controlsRef && controlsRef.current) {
			showControls
				? (controlsRef.current.style.height = 'auto')
				: (controlsRef.current.style.height = '56rem')
		}
	}, [showControls, controlsRef])

	return (
		<div className={styles.gameControlsWrap}>
			<BorderArticle>
				<div ref={controlsRef} className={styles.gameControls}>
					{isMobile && (
						<i
							onClick={() => setShowControls(!showControls)}
							className={styles.controlMobile}
						></i>
					)}

					<div>
						<div
							className={styles.controlImg}
							style={
								isMobile && !showControls ? { opacity: '0' } : { opacity: '1' }
							}
						>
							<img src={minVol} alt='min' />
						</div>

						<div
							ref={volumeRef}
							className={styles.gameVolume}
							style={
								isMobile && !showControls ? { opacity: '0' } : { opacity: '1' }
							}
						>
							<label htmlFor='volume'>
								<input
									min={0}
									max={1}
									step={0.05}
									id='volume'
									type='range'
									value={volume}
									onChange={handleVolumeChange}
								/>

								<div
									className={styles.bar}
									style={
										isMobile
											? { height: 'var(--i-position)' }
											: { width: 'var(--i-position)' }
									}
								>
									<i
										className={styles.thumb}
										style={
											isMobile
												? { bottom: 'var(--i-position)' }
												: { left: 'var(--i-position)' }
										}
									></i>
								</div>
							</label>
						</div>

						<div className={styles.controlImg}>
							<img src={maxVol} alt='max' />
						</div>
					</div>

					<div
						className={styles.controlImg}
						onClick={() => dispatch(setVolume(0))}
						style={
							isMobile && !showControls ? { opacity: '0' } : { opacity: '1' }
						}
					>
						<img
							style={{ width: '27rem', height: '27rem' }}
							src={muteVol}
							alt='mute'
						/>
					</div>
				</div>
			</BorderArticle>
		</div>
	)
}
