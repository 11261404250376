import $api from '../http'

export default class AuthService {
	static async signIn(email, password) {
		return $api.post('/sign_in', { email, password })
	}

	static async signOut(rt) {
		return $api.post('/sign_out', { rt })
	}

	static async getNotification() {
		return $api.get('/api/v1/global_notifications')
	}
}
