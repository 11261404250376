import React from 'react'
import { Intro } from './Intro'
import { Helmet } from 'react-helmet'
import { Try } from '../components/Try'

import styles from './styles.module.scss'
import { RotateCards } from './RotateCards'
import { Hcp } from './Hcp'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { PresentationPopup } from '@/popups/requestPopups/PresentationPopup'

export const Suit = () => {
	const { openPopup } = usePopup()

	const handleOpenPopup = () => {
		openPopup(<PresentationPopup />)
	}

	return (
		<>
			<Helmet title='Cosmart AVA AI Trainer COSMART SUITE' />

			<div className='container-big'>
				<div className={styles.suit_page}>
					<Intro />

					<div className='container-home'>
						<Try
							size={35}
							title={
								<span>
									The COSMART Suite ecosystem was designed to&nbsp;address
									complex, real-world challenges faced by&nbsp;pharmaceutical
									sales and marketing teams.
								</span>
							}
							button={<>Choose or create your unique training journey today!</>}
							onClickBtn={handleOpenPopup}
						/>
					</div>

					<div className='container-home'>
						<RotateCards />
					</div>

					<div className='container-home'>
						<Hcp />
					</div>
				</div>
			</div>
		</>
	)
}
