import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getProductLine,
	clearProductOptions,
} from '@/redux/slices/productSlices/productOptionsSlice'

import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { H3 } from '@/components/ui/titles/H3'
import { VideosCardLayout } from '../VideosCardLayout'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const LibraryLayout = ({ videos }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { productLine } = useSelector(state => state.productOptions)
	const { role, product_line } = useSelector(state => state.candidate)
	const [checkedLines, setCheckedLines] = useState({})

	useEffect(() => {
		if (role !== 'Representative') {
			dispatch(getProductLine())
		}
		return () => {
			dispatch(clearProductOptions())
		}
	}, [dispatch, role])

	const handleShowAllChange = () => {
		const newCheckedState = !Object.values(checkedLines).some(Boolean)
		const updatedCheckedLines = productLine.reduce((acc, line) => {
			acc[line.id] = newCheckedState
			return acc
		}, {})

		setCheckedLines(updatedCheckedLines)
		console.log('Show all')
	}

	const handleShow = line => {
		setCheckedLines(prev => ({
			...prev,
			[line.id]: !prev[line.id],
		}))

		console.log(line?.name)
	}

	useEffect(() => {
		if (role !== 'Representative') {
			dispatch(getProductLine())
		}

		return () => {
			dispatch(clearProductOptions())
		}
	}, [])

	return (
		<div className='container-big phone:p-0'>
			<div className={styles.videosWrapper}>
				<BorderArticle>
					<div className={styles.videos}>
						<div className={styles.videosHead}>
							<H3>{t('page.simulations.library.head_title')}</H3>

							<ul>
								<li>
									<RootCheckbox
										onChange={handleShowAllChange}
										label={t('page.simulations.library.checkbox_all')}
										checked={Object.values(checkedLines).every(Boolean)}
									/>
								</li>

								{productLine &&
									productLine.length > 0 &&
									productLine.map(line => (
										<li key={line?.id}>
											<RootCheckbox
												onChange={() => handleShow(line)}
												label={line?.name}
												checked={checkedLines[line.id] || false}
											/>
										</li>
									))}
							</ul>
						</div>

						<ul className={styles.videosList}>
							{videos &&
								videos.map(item => (
									<li key={item?.id}>
										<VideosCardLayout video={item} />
									</li>
								))}
						</ul>
					</div>
				</BorderArticle>
			</div>
		</div>
	)
}
