import { Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import {
	setIsMobile,
	setWidth,
	setLanguage,
	setNavigateHistory,
} from '@/redux/slices/settingsSlice'
import { checkAuth } from '@/redux/slices/candidateSlice'
import { getNotification } from '@/redux/slices/settingsSlice'
import moment from 'moment/min/moment-with-locales'
import momentTimezone from 'moment-timezone'
import Cookies from 'js-cookie'

import { AdminPanel } from '@/pages/AdminPanel'
import { UserPanel } from '@/pages/UserPanel'
import { Simulations } from '@/pages/Simulations'
import { Privacy } from '@/pages/Policy/Privacy'
import { Terms } from '@/pages/Policy/Terms'
import { Spinner } from '../ui/general/Spinner'
import { Home } from '@/pages/Home'
import { H3 } from '../ui/titles/H3'
import { Notification } from '../ui/general/Notification'
import { ChatFullGame } from '@/pages/ChatGame/ChatFullGame'
import { ChatTestGame } from '@/pages/ChatGame/ChatTestGame'
import { ChatDemoGame } from '@/pages/ChatGame/ChatDemoGame'

import 'moment/locale/ru'
import '@/styles/app.scss'
import { BgLinear } from '../ui/general/BgLinear'

const App = () => {
	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

	const { language, navigateHistory, notification } = useSelector(
		state => state.settings
	)
	const { auth, role } = useSelector(state => state.candidate)
	const [banner, setBanner] = useState(null)
	const dispatch = useDispatch()
	const location = useLocation()
	const { t } = useTranslation()

	useEffect(() => {
		const handleResize = () => {
			dispatch(setIsMobile(window.innerWidth < 768))
			dispatch(setWidth(window.innerWidth))
		}

		handleResize()

		window.addEventListener('resize', handleResize)
		window.addEventListener('orientationchange', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
			window.removeEventListener('orientationchange', handleResize)
		}
	}, [dispatch])

	useEffect(() => {
		const languageAttribute = document.querySelector('[data-ava-lang]')

		if (languageAttribute) {
			const languageValue = languageAttribute.getAttribute('data-ava-lang')

			Cookies.set('language', languageValue)
			dispatch(setLanguage(languageValue))
		} else {
			Cookies.set('language', 'en')
			dispatch(setLanguage('en'))
		}

		dispatch(checkAuth())
	}, [])

	useEffect(() => {
		if (auth) dispatch(getNotification())
	}, [auth])

	useEffect(() => {
		moment.locale(language)
	}, [language])

	useEffect(() => {
		if (!navigateHistory) dispatch(setNavigateHistory(location.pathname))
	})

	useEffect(() => {
		if (notification) {
			setBanner({
				date: momentTimezone
					.tz(notification.date, userTimeZone)
					.format('DD.MM.YYYY'),
				time: momentTimezone
					.tz(notification.date, userTimeZone)
					.format('HH:mm'),
			})
		}

		return () => {
			setBanner(null)
		}
	}, [notification])

	return (
		<Suspense fallback={<Spinner width={80} height={80} />}>
			{notification &&
				banner &&
				auth &&
				!location.pathname.includes('chat-demo') && (
					<Notification>
						<H3>
							<span>
								<Trans
									i18nKey='notification.message'
									values={{ date: banner.date, time: banner.time }}
								>
									{t('notification.message')}
								</Trans>
							</span>
						</H3>
					</Notification>
				)}

			{location.pathname.includes('chat-demo') && (
				<Notification>
					<a target='_blank' href='https://ava-request.vivanti.eu'>
						<H3>
							<span>Book a Demo!</span>
						</H3>
					</a>
				</Notification>
			)}

			{/* анимированый фон */}
			{/* <BgLinear /> */}

			<Routes>
				{auth ? (
					<>
						<Route
							path='/'
							element={
								role === 'Representative' ? (
									navigateHistory && navigateHistory !== '/' ? (
										<Navigate to={navigateHistory} />
									) : (
										<Navigate to='/simulations/call' />
									)
								) : navigateHistory && navigateHistory !== '/' ? (
									<Navigate to={navigateHistory} />
								) : (
									<Navigate to='/admin-panel/dashboard' />
								)
							}
						/>

						<Route
							path='/home/*'
							element={
								role === 'Representative' ? (
									navigateHistory && !navigateHistory.includes('home') ? (
										<Navigate to={navigateHistory} />
									) : (
										<Navigate to='/simulations/call' />
									)
								) : navigateHistory && !navigateHistory.includes('home') ? (
									<Navigate to={navigateHistory} />
								) : (
									<Navigate to='/admin-panel/dashboard' />
								)
							}
						/>

						<Route path='/simulations/*' element={<Simulations />} />
						<Route
							path='/chat-full-game/:session/:level/:id'
							element={<ChatFullGame />}
						/>
						<Route
							path='/chat-test-game/:session/:level/:id'
							element={<ChatTestGame />}
						/>
						<Route
							path='/chat-demo-game/:session/:level/:id'
							element={<ChatDemoGame />}
						/>

						{(role === 'Admin' || role === 'Manager') && (
							<Route path='/admin-panel/*' element={<AdminPanel />} />
						)}

						{role === 'Representative' && (
							<Route path='/user-panel/*' element={<UserPanel />} />
						)}
					</>
				) : (
					<>
						{language === 'en' ? ( // убрать проверку при ру версии
							<>
								<Route path='/' element={<Navigate to='/home/overview' />} />
								<Route path='/home/*' element={<Home />} />
							</>
						) : (
							<>
								<Route path='/' element={<Navigate to='/home/login' />} />
								<Route path='/home/*' element={<Home />} />
							</>
						)}
						<Route path='/privacy' element={<Privacy />} />
						<Route path='/terms' element={<Terms />} />
						<Route
							path='/simulations/*'
							element={<Navigate to='/home/login' />}
						/>
						<Route
							path='/admin-panel/*'
							element={<Navigate to='/home/login' />}
						/>
						<Route
							path='/user-panel/*'
							element={<Navigate to='/home/login' />}
						/>
						<Route
							path='/chat-full-game/:session/:level/:id'
							element={<Navigate to='/home/login' />}
						/>
						<Route
							path='/chat-test-game/:session/:level/:id'
							element={<Navigate to='/home/login' />}
						/>
						<Route
							path='/chat-demo-game/:session/:level/:id'
							element={<Navigate to='/home/login' />}
						/>
					</>
				)}

				{language === 'en' ? ( // убрать проверку при ру версии
					<Route path='*' element={<Navigate to='/home/overview' />} />
				) : (
					<Route path='*' element={<Navigate to='/home/login' />} />
				)}
			</Routes>
		</Suspense>
	)
}

export default App
