import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment/min/moment-with-locales'

import { useLevelOrder } from '@/hooks/LevelOrder'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BorderArticle } from '../BorderArticle'

import crownImage from '@/assets/images/cards/crown.svg'
import errorImage from '@/assets/images/popups/error-upload.svg'

import styles from './styles.module.scss'

export const PreviewCard = props => {
	const { name, image, due_date, mandatory, mask, levelList, id } = props

	const { levels } = useLevelOrder()
	const location = useLocation()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const [sessionType, setSessionType] = useState('')

	const { demo, role } = useSelector(state => state.candidate)

	const isDueDateApproaching = () => {
		if (due_date) {
			const now = moment()
			const dueDate = moment(due_date)
			const diffDays = dueDate.diff(now, 'days')

			return diffDays <= 14
		}
		return false
	}

	const handleClickLevel = async (item, id) => {
		if (demo && role === 'Representative') {
			navigate(`/chat-demo-game/${sessionType}/${item.value}/${id}`, {
				state: {
					level: item,
					desc: { type: sessionType, name: name, level: item.value },
				},
			})
		} else if (
			window.location.host.includes('test') ||
			window.location.host.includes('localhost')
		) {
			navigate(`/chat-test-game/${sessionType}/${item.value}/${id}`, {
				state: {
					level: item,
					desc: { type: sessionType, name: name, level: item.value },
				},
			})
		} else {
			navigate(`/chat-full-game/${sessionType}/${item.value}/${id}`, {
				state: {
					level: item,
					desc: { type: sessionType, name: name, level: item.value },
				},
			})
		}
	}

	useEffect(() => {
		if (location.pathname.includes('product')) {
			setSessionType('product')
		} else if (location.pathname.includes('call')) {
			setSessionType('scenario')
		}
	}, [location])

	return (
		<BorderArticle>
			<div className={styles.card}>
				{image && (
					<img className={styles.cardPreview} src={image} alt='preview-image' />
				)}

				{mask && (
					<div
						className={styles.maskPreview}
						style={{ background: `var(--${mask})` }}
					></div>
				)}

				{(due_date || mandatory) && isDueDateApproaching() && (
					<div className={styles.cardHead}>
						{mandatory && <img src={errorImage} alt='warning' />}

						{due_date && (
							<RootDesc>
								<span>
									{t('preview_card.date')}{' '}
									<b>{moment(due_date).format('DD.MM.YYYY')}</b>
								</span>
							</RootDesc>
						)}
					</div>
				)}

				{name && <h2>{name}</h2>}

				{levelList && (
					<ul className={styles.cardFooter}>
						{levels.map(item => (
							<li onClick={() => handleClickLevel(item, id)} key={item?.id}>
								<RootDesc>
									<span>
										<b>{item?.name}</b>
									</span>
								</RootDesc>
							</li>
						))}
					</ul>
				)}
			</div>
		</BorderArticle>
	)
}
