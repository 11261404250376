import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AvatarService from '@/services/AvatarService'
import { v4 as uuidv4 } from 'uuid'
import markdownit from 'markdown-it'

export const createScenarioSession = createAsyncThunk(
	'create/scenario_session',
	async ({ level, scenario_id }) => {
		try {
			const response = await AvatarService.createScenarioSession({
				level,
				scenario_id,
			})

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const createProductSession = createAsyncThunk(
	'create/product_session',
	async ({ level, product_id }) => {
		try {
			const response = await AvatarService.createProductSession({
				level,
				product_id,
			})

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const sendMessage = createAsyncThunk(
	'send/message',
	async ({ id, msg }) => {
		try {
			const response = await AvatarService.sendMessage({ id, msg })

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const getQuestion = createAsyncThunk('get/question', async ({ id }) => {
	try {
		const response = await AvatarService.getQuestion({ id })

		return response.data
	} catch (err) {
		throw err
	}
})

export const sendAnswer = createAsyncThunk(
	'send/answer',
	async ({ id, answer_id, answer }) => {
		try {
			const response = await AvatarService.sendAnswer({ id, answer_id, answer })

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const getScenarioFeedback = createAsyncThunk(
	'session/scenario-feedback',
	async id => {
		try {
			const response = await AvatarService.getScenarioFeedback(id)

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const getProductFeedback = createAsyncThunk(
	'session/product-feedback',
	async id => {
		try {
			const response = await AvatarService.getProductFeedback(id)

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const getRecordToken = createAsyncThunk('get/record-token', async () => {
	try {
		const response = await AvatarService.getRecordToken()

		return response.data
	} catch (err) {
		throw err
	}
})

export const getAvatarToken = createAsyncThunk('get/avatar-token', async () => {
	try {
		const response = await AvatarService.getAvatarToken()

		return response.data
	} catch (err) {
		throw err
	}
})

const initialState = {
	avatarId: 'afffcc32c461440a94eff11c61eb0eb0',
	scenario_session_id: null,
	product_session_id: null,
	sessionState: '',
	userMessage: '',
	botMessage: '',
	messages: [],
	score: null,
	questions: null,
	answer_id: null,
	isRecording: false,
	recordToken: null,
	recordHost: null,
	volume: 0.2,
	error: '',
	serverStatus: '',
	feedback: '',
}

const chatAvatarSlice = createSlice({
	name: 'chat-avatar',
	initialState,
	reducers: {
		setScore(state, action) {
			state.score = action.payload
		},
		setScenarioSessionId(state, action) {
			state.scenario_session_id = action.payload
		},
		setProductSessionId(state, action) {
			state.product_session_id = action.payload
		},
		setIsRecording(state, action) {
			state.isRecording = action.payload
		},
		setAnswerId(state, action) {
			state.answer_id = action.payload
		},
		setQuestions(state, action) {
			state.questions = action.payload
		},
		setUserMessage(state, action) {
			state.userMessage = action.payload
		},
		setBotMessage(state, action) {
			state.botMessage = action.payload
		},
		setMessages(state, action) {
			state.messages.push(action.payload)
		},
		setVolume(state, action) {
			state.volume = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearMessages(state) {
			state.messages = []
		},
		clearAvatarState(state) {
			state.userMessage = ''
			state.botMessage = ''
			state.answer_id = null
			state.score = null
			state.messages = []
			state.sessionState = ''
			state.questions = null
			state.recordToken = null
			state.recordHost = null
			state.feedback = ''
			state.error = ''
		},
	},
	extraReducers: builder => {
		builder
			//createScenarioSession
			.addCase(createScenarioSession.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(createScenarioSession.fulfilled, (state, action) => {
				state.scenario_session_id = action?.payload?.id
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(createScenarioSession.rejected, state => {
				state.error = 'Don`t create scenario session!'
				state.serverStatus = 'error'
			})

			//createProductSession
			.addCase(createProductSession.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(createProductSession.fulfilled, (state, action) => {
				state.product_session_id = action?.payload?.id
				state.questions = action?.payload?.questions_count
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(createProductSession.rejected, state => {
				state.error = 'Don`t create product session!'
				state.serverStatus = 'error'
			})

			//getScenarioFeedback
			.addCase(getScenarioFeedback.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getScenarioFeedback.fulfilled, (state, action) => {
				if (action.payload.feedback) {
					const md = markdownit()
					const html = md.render(action.payload.feedback)

					state.feedback = html
					state.error = ''
					state.serverStatus = 'success'
				}
			})
			.addCase(getScenarioFeedback.rejected, state => {
				state.error = 'Don`t get scenario feedback!'
				state.serverStatus = 'error'
			})

			//getProductFeedback
			.addCase(getProductFeedback.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getProductFeedback.fulfilled, (state, action) => {
				if (action.payload.feedback) {
					const md = markdownit()
					const html = md.render(action.payload.feedback)

					state.feedback = html
					state.error = ''
					state.serverStatus = 'success'
				}
			})
			.addCase(getProductFeedback.rejected, state => {
				state.error = 'Don`t get product feedback!'
				state.serverStatus = 'error'
			})

			//sendMessage
			.addCase(sendMessage.pending, state => {
				state.serverStatus = 'loading'
				state.sessionState = ''
			})
			.addCase(sendMessage.fulfilled, (state, action) => {
				state.botMessage = action.payload.ai
				state.score = action.payload.score
				state.sessionState = action.payload.state
				state.error = ''
				state.serverStatus = 'success'

				if (action.payload) {
					const bot = {
						messageId: uuidv4(),
						text: state.botMessage,
						sender: 'bot',
					}

					state.messages.push(bot)
				}
			})
			.addCase(sendMessage.rejected, state => {
				state.error = 'Don`t send message!'
				state.sessionState = ''
				state.serverStatus = 'error'
			})

			//getQuestion
			.addCase(getQuestion.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getQuestion.fulfilled, (state, action) => {
				state.botMessage = action.payload.text
				state.answer_id = action.payload.answer_id
				state.error = ''
				state.serverStatus = 'success'

				if (action.payload) {
					const bot = {
						messageId: uuidv4(),
						text: state.botMessage,
						sender: 'bot',
					}

					state.messages.push(bot)
					state.questions = state.questions - 1
				}
			})
			.addCase(getQuestion.rejected, state => {
				state.error = 'Don`t get question!'
				state.serverStatus = 'error'
			})

			//sendAnswer
			.addCase(sendAnswer.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(sendAnswer.fulfilled, (state, action) => {
				state.score = action.payload.score
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(sendAnswer.rejected, state => {
				state.error = 'Don`t send answer!'
				state.serverStatus = 'error'
			})

			//getRecordToken
			.addCase(getRecordToken.pending, state => {
				state.recordToken = null
				state.recordHost = null
			})
			.addCase(getRecordToken.fulfilled, (state, action) => {
				state.recordToken = action.payload.access_token
				state.recordHost = action.payload.host
				state.error = ''
			})
			.addCase(getRecordToken.rejected, state => {
				state.error = 'Don`t get record token!'
				state.recordToken = null
				state.recordHost = null
			})
	},
})

export const {
	setScore,
	setScenarioSessionId,
	setProductSessionId,
	setQuestions,
	setBotMessage,
	setUserMessage,
	setMessages,
	setVolume,
	setError,
	setServerStatus,
	clearAvatarState,
	setIsRecording,
	setAnswerId,
	clearMessages,
} = chatAvatarSlice.actions
export default chatAvatarSlice.reducer
