import React from 'react'
import { useSelector } from 'react-redux'

import { capitalize } from '@/helpers/helpers'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

export const LevelPopup = props => {
	const location = useLocation()
	const { t } = useTranslation()
	const { item, close, closeWithAvatar = null } = props
	const { serverStatus } = useSelector(state => state.chatAvatar)
	const { demo, role } = useSelector(state => state.candidate)

	const descriptionLevelList = [
		{
			id: 0,
			text: location.pathname.includes('/scenario/')
				? t('popup.simulation.easy_scenario')
				: t('popup.simulation.easy_product'),
		},
		{
			id: 1,
			text: location.pathname.includes('/scenario/')
				? t('popup.simulation.medium_scenario')
				: t('popup.simulation.medium_product'),
		},
		{
			id: 2,
			text: location.pathname.includes('/scenario/')
				? t('popup.simulation.hard_scenario')
				: t('popup.simulation.hard_product'),
		},
	]

	if (!item) return null

	return (
		<div className={styles.levelPopup}>
			<h2>
				{capitalize(item?.name)} {t('table.level')}
			</h2>

			<H3>
				{location.pathname.includes('/scenario/')
					? t('popup.simulation.title_scenario')
					: t('popup.simulation.title_product')}
			</H3>

			<RootDesc>
				<span className='block text-center'>
					{descriptionLevelList[item?.id].text}
				</span>
			</RootDesc>

			{serverStatus === 'loading' ? (
				<div className={styles.spinner}>
					<RootIcon width={26} height={26} id={'spinner-upload'} />
				</div>
			) : (
				<div className={styles.buttons}>
					<RootButton
						txt={
							demo && role === 'Representative'
								? t('button.start_chat')
								: t('button.continue')
						}
						type={'button'}
						onClick={close}
					/>

					{closeWithAvatar && (
						<ControlButton
							color='var(--violet)'
							txt={'Continue with avatar'}
							onClick={closeWithAvatar}
						/>
					)}
				</div>
			)}
		</div>
	)
}
