import React from 'react'
import { useLocation } from 'react-router-dom'
import { useLevelOrder } from '@/hooks/LevelOrder'
import { useTranslation } from 'react-i18next'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'

import styles from './styles.module.scss'

export const ChatDesc = () => {
	const location = useLocation()
	const { t } = useTranslation()
	const { currentLevel } = useLevelOrder()

	return (
		<div className={styles.chatDesc}>
			<RootDesc>
				<span>
					{location?.state?.desc?.type === 'scenario'
						? t('page.simulations.tab.call')
						: t('page.simulations.tab.product')}
				</span>
			</RootDesc>

			<h4>{location?.state?.desc?.name}</h4>

			<h6>
				{t('table.level')}: {currentLevel(location?.state?.desc?.level)}
			</h6>
		</div>
	)
}
