import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'

import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'

export const BenefitsHoverCard = ({ item, itemChild }) => {
	const { isMobile } = useSelector(state => state.settings)
	const [showCard, setShowCard] = useState(false)

	const { ref, inView, entry } = useInView({
		threshold: 0.5,
	})

	useEffect(() => {
		if (inView) {
			setShowCard(true)
		}
	}, [inView])

	return (
		<div
			ref={ref}
			style={showCard ? { opacity: '1' } : { opacity: '0' }}
			className={
				item.id === itemChild
					? `${styles.benefits_root_item} ${styles.benefits_item}`
					: styles.benefits_item
			}
		>
			<BorderArticle>
				<div>
					<i></i>

					<RootIcon
						width={isMobile ? 32 : 64}
						height={isMobile ? 32 : 64}
						id={item.icon}
					/>

					<h3>{item.title}</h3>

					<span>{item.subtitle}</span>
				</div>
			</BorderArticle>
		</div>
	)
}
