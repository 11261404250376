import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

import { Logo } from '@/components/ui/general/Logo'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

export const RootHeader = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const test_server = window.location.host.includes('test')
	const local_server = window.location.host.includes('localhost')

	const navList = [
		{
			id: 0,
			name: 'Overview',
			link: 'overview',
			icon: null,
		},
		{
			id: 1,
			name: 'Features',
			link: 'features',
			icon: null,
		},
		{
			id: 2,
			name: 'Use Cases',
			link: 'cases',
			icon: null,
		},
		// TODO: расскомментировать когда будет еще одна статья
		// 	id: 3,
		// 	name: 'Analytics',
		// 	link: 'articles',
		// 	icon: null,
		// },
		{
			id: 3,
			name: 'COSMART SUITE',
			link: 'suit',
			icon: null,
		},
		{
			id: 4,
			name: 'Customer Login',
			link: 'login',
			icon: 'header_login_icon',
		},
	]

	const languages = [
		{
			id: 0,
			name: 'English',
			shortName: 'EN',
			value: 'en',
			unicode: getUnicodeFlagIcon('GB'),
			link: test_server
				? 'https://ava-test.vivanti.eu'
				: local_server
				? 'http://localhost:3000'
				: 'https://ava.vivanti.eu',
		},
		{
			id: 1,
			name: 'Russian',
			shortName: 'RU',
			unicode: getUnicodeFlagIcon('RU'),
			value: 'ru',
			link: test_server
				? 'https://ava-test.cosmart.app'
				: local_server
				? 'http://localhost:3000'
				: 'https://ava.vivanti.eu',
		},
	]

	const { isMobile, language } = useSelector(state => state.settings)
	const [isOpen, setIsOpen] = useState(false)
	const [mobileMenu, setMobileMenu] = useState(false)
	const [selectedOption, setSelectedOption] = useState(languages[0].shortName)
	const setRef = useRef(null)

	const toggleSelect = () => {
		setIsOpen(!isOpen)
	}

	const handleOptionClick = option => {
		setSelectedOption(option.shortName)
		setIsOpen(false)
	}

	const handleClickOutside = event => {
		if (setRef.current && !setRef.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	const handleClickBurger = () => {
		setMobileMenu(!mobileMenu)
	}

	const handleClickNavLink = link => {
		navigate(link)

		if (isMobile) {
			handleClickBurger()
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className={styles.header_wrapper}>
			<div className='container-big'>
				<div className={styles.header_wrap}>
					<div className={styles.header_logo}>
						<Logo direction={'footer'} />
					</div>

					{!isMobile && (
						<nav
							className={styles.header_nav}
							style={
								language === 'ru'
									? { pointerEvents: 'none', visibility: 'hidden' }
									: {}
							}
						>
							<ul>
								{navList &&
									navList.length > 0 &&
									navList.map(item => (
										<li
											className={styles.header_nav_item}
											key={item.id}
											onClick={() => handleClickNavLink(item.link)}
											style={
												location.pathname.includes(item.link)
													? {
															pointerEvents: 'none',
															background:
																'linear-gradient(135deg, #8692cb 0%, #b262c9 100%)',
													  }
													: { pointerEvents: 'all' }
											}
										>
											{!item.icon && <i></i>}

											<RootDesc>
												<span>{item.name}</span>
											</RootDesc>

											{item.icon && (
												<RootIcon width={16} height={16} id={item.icon} />
											)}
										</li>
									))}
							</ul>
						</nav>
					)}

					<div ref={setRef} className={styles.header_set}>
						<div className={styles.set_header} onClick={toggleSelect}>
							<RootDesc>
								<span>{selectedOption}</span>
							</RootDesc>

							<i
								style={
									isOpen
										? { transform: 'rotate(180deg)' }
										: { transform: 'rotate(0deg)' }
								}
							></i>
						</div>

						{isOpen && (
							<div className={styles.set_list}>
								<BorderArticle>
									<div className={styles.set_body}>
										<ul>
											{languages.map(option => (
												<li
													style={
														selectedOption.includes(option.shortName)
															? {
																	pointerEvents: 'none',
																	background: '#fcf7fd',
																	opacity: '0.5',
															  }
															: {}
													}
													className={
														selectedOption.includes(option.shortName)
															? styles.activeOption
															: ''
													}
													key={option.id}
													onClick={() => handleOptionClick(option)}
												>
													<a href={option.link} rel='noopener noreferrer'>
														{/* <img src={option.unicode} alt="country-unicode" /> */}

														<SmallDesc>
															<span>
																{option.unicode} {option.name}
															</span>
														</SmallDesc>
													</a>
												</li>
											))}
										</ul>
									</div>
								</BorderArticle>
							</div>
						)}
					</div>

					{isMobile && language !== 'ru' && (
						<>
							<div
								onClick={handleClickBurger}
								className={styles.header_mobile_burger}
							>
								<RootIcon
									width={28}
									height={28}
									id={mobileMenu ? 'burger-close' : 'burger-menu'}
								/>
							</div>

							<nav
								className={`${styles.header_mobile_menu} ${
									mobileMenu ? styles.header_mobile_menu_active : ''
								}`}
							>
								<ul>
									{navList &&
										navList.length > 0 &&
										navList.map(item => (
											<li
												key={item.id}
												onClick={() => handleClickNavLink(item.link)}
											>
												<RootDesc>
													<span>{item.name}</span>
												</RootDesc>

												{item.icon && (
													<RootIcon width={16} height={16} id={item.icon} />
												)}
											</li>
										))}
								</ul>
							</nav>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
