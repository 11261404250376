import React from 'react'
import styles from './styles.module.scss'
import { HeroVideo } from '../components/HeroVideo'
import { HeroTitle } from '../components/HeroTitle'
import { HeroBlock } from '../components/HeroBlock'

import launch from '@/assets/images/home/cases/launch.svg'
import regular from '@/assets/images/home/cases/regular.svg'
import omni from '@/assets/images/home/cases/omni.svg'
import shift from '@/assets/images/home/cases/shift.svg'
import handling from '@/assets/images/home/cases/handling.svg'
import feedback from '@/assets/images/home/cases/feedback.svg'
import wining from '@/assets/images/home/cases/wining.svg'
import intelligens from '@/assets/images/home/cases/intelligens.svg'
import consistent from '@/assets/images/home/cases/consistent.svg'

import launch_hover from '@/assets/images/home/cases/launch-hover.svg'
import regular_hover from '@/assets/images/home/cases/regular-hover.svg'
import omni_hover from '@/assets/images/home/cases/omni-hover.svg'
import shift_hover from '@/assets/images/home/cases/shift-hover.svg'
import handling_hover from '@/assets/images/home/cases/handling-hover.svg'
import feedback_hover from '@/assets/images/home/cases/feedback-hover.svg'
import wining_hover from '@/assets/images/home/cases/wining-hover.svg'
import intelligens_hover from '@/assets/images/home/cases/intelligens-hover.svg'
import consistent_hover from '@/assets/images/home/cases/consistent-hover.svg'

export const Intro = () => {
	const cardList = [
		{
			id: 0,
			icon: launch,
			iconHover: launch_hover,
			title: <>Product Launch</>,
			subtitle: (
				<>
					Verified deep product <br />
					knowledge and objections <br />
					handling.
				</>
			),
		},
		{
			id: 1,
			icon: regular,
			iconHover: regular_hover,
			title: (
				<>
					Regular calls with one <br />
					or&nbsp;several line products
				</>
			),
			subtitle: (
				<>
					Maximised impact <br />
					by&nbsp;delivering accurate strategy <br />
					and key messages.
				</>
			),
		},
		{
			id: 2,
			icon: omni,
			iconHover: omni_hover,
			title: <>Omni Rep Strategy</>,
			subtitle: (
				<>
					Aligned Sales and Marketing <br />
					Efforts.
				</>
			),
		},
		{
			id: 3,
			icon: shift,
			iconHover: shift_hover,
			title: <>Strategy Shift</>,
			subtitle: (
				<>
					Rapid Reps retraining leading <br />
					to&nbsp;reshaped perception <br />
					of&nbsp;the company/product.
				</>
			),
		},
		{
			id: 4,
			icon: handling,
			iconHover: handling_hover,
			title: <>Handling Objections</>,
			subtitle: (
				<>
					Confident competitive <br />
					positioning reinforcing <br />
					the product’s&nbsp;benefits.
				</>
			),
		},
		{
			id: 5,
			icon: feedback,
			iconHover: feedback_hover,
			title: <>Feedback Calls</>,
			subtitle: (
				<>
					Efficient feedback collection, <br />
					including pharmacovigilance <br />
					protocols.
				</>
			),
		},
		{
			id: 6,
			icon: wining,
			iconHover: wining_hover,
			title: (
				<>
					Winning with non-loyal <br /> HCP
				</>
			),
			subtitle: (
				<>
					Stronger engagement <br />
					techniques build trust <br />
					and boost HCP retention.
				</>
			),
		},
		{
			id: 7,
			icon: intelligens,
			iconHover: intelligens_hover,
			title: (
				<>
					Calls to&nbsp;different <br />
					HCP profiles: conservative/ <br />
					innovative, novice/ <br />
					experienced
				</>
			),
			subtitle: (
				<>
					Improved emotional <br /> intelligence.
				</>
			),
		},
		{
			id: 8,
			icon: consistent,
			iconHover: consistent_hover,
			title: (
				<>
					Calls to&nbsp;different <br />
					HCP Roles
				</>
			),
			subtitle: <>Consistent KPI delivery.</>,
		},
	]

	return (
		<div className={styles.intro}>
			<HeroVideo top={'-550'} />

			<HeroTitle>
				<span>
					<strong>AVA</strong> AI Trainer
				</span>

				<span>Use Cases</span>
			</HeroTitle>

			<div className='container-home'>
				<HeroBlock
					cardsTitle={<>Use Cases for Commercial Teams</>}
					cards={cardList}
					itemHover={'linear-gradient(135deg, #8692CB 0%, #B262C9 100%)'}
					description={
						<span>
							Imagine AVA as&nbsp;a&nbsp;team of&nbsp;expert coaches, each
							specialising in&nbsp;a different field, seamlessly collaborating
							to&nbsp;meet specific audience needs. Similarly,
							AVA’s&nbsp;AI-powered engine employs multiple specialised agents,
							each responsible for a&nbsp;distinct area of&nbsp;expertise,
							enabling the configuration and evaluation of&nbsp;nearly any
							communication scenario.
							<br />
							<br />
							This flexibility allows AVA to&nbsp;cater to&nbsp;various use
							cases, from onboarding new employees to&nbsp;refining advanced
							sales strategies, ensuring that every scenario is&nbsp;tailored
							to&nbsp;achieve specific learning and performance outcomes.
						</span>
					}
				/>
			</div>
		</div>
	)
}
