import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
	getScenarioFeedback,
	getProductFeedback,
	clearMessages,
} from '@/redux/slices/avatarSlices/chatAvatarSlice'
import { useScoreValue } from '@/hooks/ScoreValue'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { Spinner } from '@/components//ui/general/Spinner'

import low from '@/assets/images/popups/low-rating.svg'
import medium from '@/assets/images/popups/medium-rating.svg'
import high from '@/assets/images/popups/high-rating.svg'
import logo from '@/assets/images/general/logo.svg'

import styles from './styles.module.scss'

const FeedbackCard = props => {
	const [isOpen, setIsOpen] = useState(false)
	const item = props.feedback

	return (
		<div className={styles.feedbackCard} key={item?.id}>
			<div onClick={() => setIsOpen(!isOpen)} className={styles.cardHead}>
				<h4>{item?.title}</h4>

				<i
					style={
						isOpen
							? { transform: 'rotate(180deg)' }
							: { transform: 'rotate(0deg)' }
					}
				></i>
			</div>

			{isOpen && (
				<div className={styles.cardBody}>
					{item?.products && (
						<ul>
							{item?.products.map(product => (
								<li key={product?.id}>
									<div className='flex items-center'>
										<RootDesc>
											<b>{product?.name}</b>
										</RootDesc>
									</div>

									<img src={product?.rating} alt='rating' />
								</li>
							))}
						</ul>
					)}

					<div className={item?.products ? 'max-w-[488rem]' : 'max-w-[100%]'}>
						<RootDesc>
							<span>{item?.text}</span>
						</RootDesc>
					</div>
				</div>
			)}
		</div>
	)
}

export const AiFeedbackPopup = props => {
	const dispatch = useDispatch()
	const currentScore = useScoreValue()
	const { t } = useTranslation()
	const { again, quit } = props
	const {
		feedback,
		scenario_session_id,
		product_session_id,
		serverStatus,
		score,
	} = useSelector(state => state.chatAvatar)
	const { demo, role } = useSelector(state => state.candidate)

	useEffect(() => {
		const getFeedback = async () => {
			try {
				if (product_session_id) {
					await dispatch(getProductFeedback(product_session_id))
				} else if (scenario_session_id) {
					await dispatch(getScenarioFeedback(scenario_session_id))
				}
				dispatch(clearMessages())
			} catch (error) {
				console.log('Error receiving feedback: ', error)
			}
		}
		getFeedback()
	}, [dispatch])

	return (
		<div className={styles.feedbackPopup}>
			<div className={styles.logo}>
				<img src={logo} alt='ava-logo' />
			</div>
			<div className={styles.feedbackTitle}>
				<H3>{t('popup.feedback.title')}</H3>
			</div>
			<div className={styles.feedbackContent}>
				{serverStatus === 'loading' ? (
					<Spinner width={80} height={80} />
				) : (
					<>
						<RootDesc>
							<div
								className={styles.feedbackMarkdown}
								dangerouslySetInnerHTML={{ __html: feedback }}
							/>
						</RootDesc>

						<div className={styles.feedbackBtns}>
							<RootButton
								txt={t('button.again')}
								type={'button'}
								onClick={again}
							/>
							<RootButton
								type={'button'}
								onClick={quit}
								txt={
									demo && role === 'Representative'
										? t('button.close_chat')
										: t('button.exit_simulation')
								}
							/>
							{(window.location.host.includes('test') ||
								window.location.host.includes('localhost')) && (
								<a
									href='https://forms.office.com/Pages/ResponsePage.aspx?id=Bn9W-X40r0aU5JZznAAX75csqYxCBelDk8puwfijW09UN1E3SDM2RlNGREFST0dUM0NDOVBUUExLSiQlQCN0PWcu'
									target='_blank'
								>
									<RootDesc>
										<span>{t('button.leave_feedback_simulation')}</span>
									</RootDesc>
								</a>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	)
}
