import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
import AuthService from '@/services/AuthService'

export const getNotification = createAsyncThunk('notification', async () => {
	try {
		const response = await AuthService.getNotification()

		return response.data
	} catch (err) {
		throw new Error('Failed to get notification: ', err)
	}
})

const initialState = {
	language: '',
	isMobile: window.innerWidth < 768,
	width: window.innerWidth,
	navigateHistory: null,
	notification: null,
}

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setIsMobile(state, action) {
			state.isMobile = action.payload
		},
		setWidth(state, action) {
			state.width = action.payload
		},
		setLanguage(state, action) {
			state.language = action.payload
		},
		setNavigateHistory(state, action) {
			state.navigateHistory = action.payload
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getNotification.pending, state => {
				state.notification = null
			})
			.addCase(getNotification.fulfilled, (state, action) => {
				const notification = action.payload || null

				if (notification && notification.length > 0) {
					const currentDate = moment()

					notification.map(item => {
						const [dateString, message] = item
						const date = moment(dateString)
						const daysDifference = date.diff(currentDate, 'days')

						if (daysDifference <= 4) {
							state.notification = {
								date: dateString || null,
								message: message || null,
							}
						} else {
							state.notification = null
						}
					})
				}
			})
			.addCase(getNotification.rejected, state => {
				state.notification = null
			})
	},
})

export const { setIsMobile, setNavigateHistory, setWidth, setLanguage } =
	settingsSlice.actions
export default settingsSlice.reducer
