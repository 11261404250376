import { useSelector } from 'react-redux'
import moment from 'moment/min/moment-with-locales'
import { useTranslation } from 'react-i18next'
import { capitalize } from '@/helpers/helpers'

import { Link } from 'react-router-dom'
import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { StatisticCardsLayout } from '@/components/layouts/StatisticCardsLayout'

import arrowImage from '@/assets/images/buttons/arrow-link.svg'
import activeImage from '@/assets/images/cards/user.png'
import successImage from '@/assets/images/cards/user-set.png'
import rejectImage from '@/assets/images/cards/user-rem.png'

import styles from './Hello.module.scss'

export const Hello = () => {
	const { t } = useTranslation()

	const overviewList = [
		{
			id: 0,
			icon: activeImage,
			title: t('overview_card.active_title'),
			total: 540,
			totalChange: 5,
		},
		{
			id: 1,
			icon: successImage,
			title: t('overview_card.success_title'),
			total: 647,
			totalChange: 25,
		},
		{
			id: 2,
			icon: rejectImage,
			title: t('overview_card.reject_title'),
			total: 254,
			totalChange: -16,
		},
	]

	const { name } = useSelector(state => state.candidate)

	return (
		<div className={styles.hello}>
			<div className={styles.helloHeader}>
				<h1>
					{t('page.dashboard.hello_title')} <b>{name}</b>
				</h1>

				<span>
					{capitalize(moment().format('MMMM'))}, <b>{moment().format('DD')}</b>
				</span>
			</div>

			<div className={styles.overview}>
				<div className={styles.overviewHead}>
					<H3>{t('page.dashboard.overview_title')}</H3>

					<Link to={'/admin-panel/analytics/scenario'}>
						<RootDesc>
							<span>{t('page.dashboard.overview_link')}</span>
						</RootDesc>

						<div className={styles.chart}>
							<img src={arrowImage} alt='arrow' />
						</div>
					</Link>
				</div>

				<StatisticCardsLayout list={overviewList} />
			</div>
		</div>
	)
}
