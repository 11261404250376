import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
	setError,
	signOut,
	clearCandidate,
} from '@/redux/slices/candidateSlice'

import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import DropdownMenu from '@/components/ui/general/DropdownMenu'
import { Logo } from '@/components/ui/general/Logo'
import { useCurrentRole } from '@/hooks/CurrentRole'

import styles from './styles.module.scss'
import { useEffect } from 'react'
import { ChatDesc } from '../../ui/general/ChatDesc'

export const Header = () => {
	const currentRole = useCurrentRole()
	const { t } = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { isMobile } = useSelector(state => state.settings)
	const { id, name, role, cover } = useSelector(state => state.candidate)

	const adminMenuList = [
		{
			id: 0,
			iconId: 'profile',
			name: t('header.menu.profile'),
			link: `/admin-panel/profile/${id}`,
		},
	]

	const menuList = [
		{
			id: 0,
			iconId: 'profile',
			name: t('header.menu.profile'),
			link:
				role !== 'Representative'
					? `/admin-panel/profile/${id}`
					: `/user-panel/profile/${id}`,
		},
		{
			id: 1,
			iconId: 'chart',
			name: t('header.menu.history'),
			link:
				role !== 'Representative'
					? '/admin-panel/analytics/scenario'
					: '/user-panel/analytics/scenario',
		},
	]

	const excludedPaths = [
		'simulations',
		'privacy',
		'terms',
		'disclaimer',
		'chat',
	]

	const isExcludedPath = excludedPaths.some(path =>
		location.pathname.includes(path)
	)

	const handleClickLogout = async () => {
		const resultAction = await dispatch(signOut())

		if (signOut.fulfilled.match(resultAction)) {
			navigate('/home/login')

			dispatch(clearCandidate())
		} else {
			dispatch(setError(t('server_error.logout')))
		}
	}

	return (
		<div className='container-big'>
			<div className={styles.header}>
				<Logo />

				{location.pathname.includes('chat') && <ChatDesc />}

				{isExcludedPath || isMobile ? (
					<div className={styles.userInfoSmall}>
						<DropdownMenu
							head={<RootDesc>{name}</RootDesc>}
							list={role === 'Admin' ? adminMenuList : menuList}
							top={35}
						>
							<div onClick={handleClickLogout} className={styles.userLogout}>
								<RootIcon id='logout' />

								<RootDesc>
									<span className='text-[var(--grey)]'>
										{t('header.logout')}
									</span>
								</RootDesc>
							</div>
						</DropdownMenu>

						<div className={styles.userPhoto}>
							<img src={cover} alt='avatar' />
						</div>
					</div>
				) : (
					<div className='container-small'>
						<BorderArticle>
							<div className={styles.userInfoBig}>
								<div className={styles.userProfile}>
									<div className={styles.userPhoto}>
										<img src={cover} alt='avatar' />
									</div>

									{role === 'Admin' || role === 'Manager' ? (
										<DropdownMenu
											head={<H3>{name}</H3>}
											list={adminMenuList}
											top={45}
										/>
									) : (
										<H3>{name}</H3>
									)}

									<RootDesc>
										<span className='text-[var(--grey)]'>
											{currentRole(role)}
										</span>
									</RootDesc>
								</div>

								<div onClick={handleClickLogout} className={styles.adminLogout}>
									<RootIcon id='logout' />

									<RootDesc>
										<span className='text-[var(--grey)]'>
											{t('header.logout')}
										</span>
									</RootDesc>
								</div>
							</div>
						</BorderArticle>
					</div>
				)}
			</div>
		</div>
	)
}
