import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import StreamingAvatar, {
	VoiceEmotion,
	AvatarQuality,
	StreamingEvents,
} from '@heygen/streaming-avatar'

import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { useChatTime } from '@/hooks/ChatTime'

import { Header } from '@/components/layouts/Header'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Spinner } from '@/components/ui/general/Spinner'
import { LevelPopup } from '@/popups/simulationPopups/LevelPopup'
import { AiFeedbackPopup } from '@/popups/simulationPopups/AiFeedbackPopup'
import { AvatarErrorPopup } from '@/popups/simulationPopups/AvatarErrorPopup'
import { Controls } from './Controls'
import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { Timer } from './Timer'
import { Chat } from './Chat'

import {
	createProductSession,
	createScenarioSession,
	setProductSessionId,
	setScenarioSessionId,
	setServerStatus,
	getQuestion,
	clearAvatarState,
	getAvatarToken,
} from '@/redux/slices/avatarSlices/chatAvatarSlice'

import doctorImage from '@/assets/images/general/doctor.png'

// раскоментировать для теста видео
// import videoMp4 from '@/assets/fake_avatar.mp4'
// import videoWebm from '@/assets/fake_avatar.webm'
// import videoMov from '@/assets/fake_avatar.mov'

import styles from './styles.module.scss'

export const ChatDemoGame = () => {
	// закоментировать для теста видео
	const videoMp4 = '/video/fake_avatar.mp4'
	const videoWebm = '/video/fake_avatar.webm'
	const videoMov = '/video/fake_avatar.mov'

	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const params = useParams()
	const { openPopup, closePopup } = usePopup()

	const remainingTime = useMemo(
		() =>
			params.session === 'scenario'
				? location?.state?.level?.remainingScenarioTime
				: location?.state?.level?.remainingProductTime,
		[params.session, location?.state?.level]
	)

	const { language } = useSelector(state => state.settings)
	const [chatTime, setChatTimeState, restart] = useChatTime(remainingTime)
	const { isMobile } = useSelector(state => state.settings)
	const {
		volume,
		botMessage,
		messages,
		product_session_id,
		scenario_session_id,
		score,
		questions,
		sessionState,
		avatarId,
		error,
	} = useSelector(state => state.chatAvatar)

	const fakeVideoRef = useRef(null)
	const videoRef = useRef(null)
	const [avatar, setAvatar] = useState(null)
	const [sessionData, setSessionData] = useState(null)
	const [avatarLoading, setAvatarLoading] = useState(false)
	const [avatarToken, setAvatarToken] = useState(null)
	const [silence, setSilence] = useState(false)
	const [countdown, setCountdown] = useState(115)

	const getToken = async () => {
		try {
			dispatch(setServerStatus('loading'))

			const resultAction = await dispatch(getAvatarToken())

			if (getAvatarToken.fulfilled.match(resultAction)) {
				const token = unwrapResult(resultAction)?.data?.token
				if (!token) throw new Error('Token does not exist!')

				setAvatarToken(token)
			}
		} catch (error) {
			console.error('Error receiving token: ', error)
			dispatch(setServerStatus('error'))
		} finally {
			dispatch(setServerStatus(''))
		}
	}

	const initSession = async () => {
		try {
			if (params && params?.session === 'scenario') {
				await dispatch(
					createScenarioSession({
						level: params?.level,
						scenario_id: params?.id,
					})
				)
			} else if (params && params?.session === 'product') {
				const resultAction = await dispatch(
					createProductSession({
						level: params?.level,
						product_id: params?.id,
					})
				)

				if (createProductSession.fulfilled.match(resultAction)) {
					const id = unwrapResult(resultAction)?.id
					if (!id) throw new Error('Session ID does not exist!')

					await dispatch(getQuestion({ id: id }))
				}
			}

			await setChatTimeState(remainingTime)
		} catch (error) {
			console.log('Session initialization error: ', error)
			dispatch(setServerStatus('error'))
		} finally {
			dispatch(setServerStatus(''))
		}
	}

	const initializeAvatarSession = async () => {
		if (!avatarToken) return

		dispatch(setServerStatus('loading'))

		try {
			const newAvatar = new StreamingAvatar({
				token: avatarToken,
				onError: error => console.error('[AvatarSDK] SDK Error: ', error),
			})

			const data = await newAvatar.createStartAvatar({
				quality: AvatarQuality.High,
				avatarName: avatarId,
				voice: {
					rate: 1.5, // 0.5 ~ 1.5
					emotion: VoiceEmotion.SERIOUS,
				},
				language: language,
				disableIdleTimeout: true,
			})

			setSessionData(data)
			setAvatar(newAvatar)
			setCountdown(115)
			dispatch(setServerStatus('success'))

			newAvatar.on(StreamingEvents.STREAM_READY, handleStreamReady)
			newAvatar.on(
				StreamingEvents.STREAM_DISCONNECTED,
				handleStreamDisconnected
			)
		} catch (error) {
			console.error('Avatar initialization error: ', error)
			dispatch(setServerStatus('error'))

			openPopup(
				<AvatarErrorPopup
					onClickTryAgain={handleTryAgain}
					onClickExit={handleQuit}
				/>,
				{ closeButton: false }
			)
		} finally {
			setAvatarLoading(false)
			dispatch(setServerStatus(''))
		}
	}

	const handleTryAgain = async () => {
		setAvatarLoading(true)
		dispatch(setServerStatus('loading'))

		try {
			await terminateAvatarSession()
			await dispatch(clearAvatarState())
			await dispatch(setScenarioSessionId(null))
			await dispatch(setProductSessionId(null))
			await initSession()
			await restart(new Date(Date.now() + remainingTime), false)
			await getToken()
		} catch (error) {
			console.log('Retry error: ', error)
			dispatch(setServerStatus('error'))
		} finally {
			closePopup()
			dispatch(setServerStatus(''))
		}
	}

	const terminateAvatarSession = async () => {
		if (videoRef?.current) videoRef.current.srcObject = null
		if (avatar) await avatar.stopAvatar()

		try {
			setAvatar(null)
			setSessionData(null)
			setAvatarLoading(false)
			setCountdown(115)
			setSilence(false)
		} catch (error) {
			console.log('Avatar reset error: ', error)
			dispatch(setServerStatus('error'))
		} finally {
			dispatch(setServerStatus(''))
		}
	}

	const handleSpeak = async msg => {
		try {
			setCountdown(115)
			setSilence(false)

			await avatar.speak({ text: msg, task_type: 'repeat' })
		} catch (error) {
			console.log('Avatar speak error: ', error)
			dispatch(setServerStatus('error'))
		} finally {
			dispatch(setServerStatus(''))
		}
	}

	const handleStreamDisconnected = useCallback(() => {
		if (videoRef?.current) {
			const stream = videoRef.current.srcObject

			if (stream) {
				stream.getTracks().forEach(track => track.stop())
			}

			videoRef.current.srcObject = null
		}
	}, [videoRef])

	const handleStreamReady = useCallback(
		event => {
			if (event.detail && videoRef?.current) {
				videoRef.current.srcObject = event.detail
				videoRef.current.play().catch(console.error)
			}
		},
		[videoRef]
	)

	const handleQuit = async () => {
		setAvatarLoading(true)
		dispatch(setServerStatus('loading'))

		try {
			await terminateAvatarSession()
			await dispatch(clearAvatarState())
			await dispatch(setScenarioSessionId(null))
			await dispatch(setProductSessionId(null))
			await setChatTimeState(remainingTime)
			await restart(new Date(Date.now() + remainingTime), false)

			setAvatarToken(null)
		} catch (error) {
			console.log('Exit error: ', error)
			dispatch(setServerStatus('error'))
		} finally {
			closePopup()
			dispatch(setServerStatus(''))
		}
	}

	const handleClickQuitBtn = async () => {
		setAvatarLoading(true)
		dispatch(setServerStatus('loading'))
		await terminateAvatarSession()

		try {
			if (messages.length > 1) {
				await restart(new Date(Date.now()), false)
			} else {
				await dispatch(setScenarioSessionId(null))
				await dispatch(setProductSessionId(null))
			}

			setAvatarToken(null)
		} catch (error) {
			console.log('Session logout error: ', error)
		} finally {
			dispatch(setServerStatus(''))
		}
	}

	const handleCloseLevelPopup = async () => {
		setAvatarLoading(true)

		await initSession()
		await getToken()
	}

	useEffect(() => {
		document.body.style.background = '#b7d7e7'

		if (!location.state) navigate('/simulations/call')

		return () => {
			document.body.style.background =
				'linear-gradient( 90deg, rgba(144, 178, 234, 0.4) 0%,rgba(245, 129, 213, 0.4)100%),#fff'

			terminateAvatarSession()

			dispatch(clearAvatarState())
			dispatch(setProductSessionId(null))
			dispatch(setScenarioSessionId(null))

			setChatTimeState(null)
			closePopup()
		}
	}, [])

	useEffect(() => {
		const userMessages = messages.filter(m => m?.sender === 'user').length
		const botMessages = messages.filter(m => m?.sender === 'bot').length

		const handleSessionEnd = async () => {
			if (
				(scenario_session_id &&
					(score === 0 ||
						sessionState === 'ended' ||
						questions === 0 ||
						chatTime === 0)) ||
				(product_session_id &&
					(chatTime === 0 || (questions === 0 && userMessages === botMessages)))
			) {
				openPopup(
					<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />,
					{
						closeButton: false,
					}
				)

				await terminateAvatarSession()
			}
		}

		handleSessionEnd()
	}, [
		chatTime,
		questions,
		score,
		sessionState,
		scenario_session_id,
		product_session_id,
		messages,
	])

	useEffect(() => {
		if (avatar && sessionData && botMessage !== '') {
			handleSpeak(botMessage)
		}
	}, [botMessage, avatar, sessionData])

	useEffect(() => {
		if (videoRef && videoRef.current) {
			videoRef.current.volume = volume
		}
	}, [volume, videoRef])

	useEffect(() => {
		if (avatarToken) {
			initializeAvatarSession()
		}
	}, [avatarToken])

	useEffect(() => {
		if (avatar && silence) {
			handleSpeak(t('page.chat.silence_message'))
		} else {
			setCountdown(115)
			setSilence(false)
		}
	}, [silence, avatar])

	useEffect(() => {
		if (countdown <= 0) {
			setSilence(true)

			return
		}

		const timer = setInterval(() => {
			setCountdown(prev => prev - 1)
		}, 1000)

		return () => clearInterval(timer)
	}, [countdown])

	return (
		<div className={styles.gameWrapper}>
			<Header />

			<div className='container-big phone:p-0 h-[100%] flex'>
				<div className={styles.game}>
					<div className={styles.doctor}>
						{avatar && sessionData && error === '' ? (
							<>
								<div className={styles.avatarWrapper}>
									<div className={styles.avatarPlayer}>
										{avatarLoading ? (
											<Spinner width={80} height={80} />
										) : (
											<video
												style={{ pointerEvents: 'none' }}
												id='avatarVideo'
												ref={videoRef}
												autoPlay
												playsInline
											/>
										)}
									</div>

									<Controls />
								</div>

								{!avatarLoading && (
									<div onClick={handleClickQuitBtn} className={styles.quitBtn}>
										<i>
											<RootIcon width={32} height={32} id={'quit'} />
										</i>

										{!isMobile && (
											<RootDesc>
												<span>{t('button.finish_chat')}</span>
											</RootDesc>
										)}
									</div>
								)}
							</>
						) : (
							<>
								<div className={styles.doctorVideo}>
									{avatarLoading && <Spinner width={80} height={80} />}

									{videoMp4 && videoWebm && videoMov && (
										<video
											ref={fakeVideoRef}
											autoPlay={true}
											loop={true}
											muted={true}
											poster={doctorImage}
										>
											<source src={videoMov} type='video/quicktime' />
											<source src={videoWebm} type='video/webm' />
											<source src={videoMp4} type='video/mp4' />
										</video>
									)}
								</div>

								<div className={styles.levelPopup}>
									<BorderArticle>
										<LevelPopup
											close={handleCloseLevelPopup}
											item={location?.state?.level}
										/>
									</BorderArticle>
								</div>
							</>
						)}
					</div>

					{avatar && sessionData && (
						<>
							<Timer time={chatTime} />

							<div className={styles.gameChatWrapper}>
								<Chat time={chatTime} />
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
